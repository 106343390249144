var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        transition: "slide-y-transition",
        "nudge-left": "40",
        "offset-y": ""
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _vm._t(
                  "default",
                  [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                        on
                      ),
                      [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                      1
                    )
                  ],
                  { attrs: attrs, on: on }
                )
              ]
            }
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.showMenu,
        callback: function($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "mx-auto", attrs: { "max-width": "300" } },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            _vm._l(_vm.actions, function(action, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  attrs: { disabled: action.disabled },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.actionClicked(action)
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    { attrs: { disabled: action.disabled } },
                    [_c("v-icon", [_vm._v(_vm._s(action.icon))])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: " mr-3" },
                    [_c("v-list-item-title", [_vm._v(_vm._s(action.label))])],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm.payloadModal
        ? _c("ContractPayloadModal", {
            attrs: { selectedContract: _vm.selectedContract },
            on: { closePayloadModal: _vm.closePayloadModal },
            model: {
              value: _vm.payloadModal,
              callback: function($$v) {
                _vm.payloadModal = $$v
              },
              expression: "payloadModal"
            }
          })
        : _vm._e(),
      _vm.uploadModal
        ? _c("ContractUploadModal", {
            attrs: { reupload: true, uploadId: _vm.selectedContract.upload_id },
            on: { closeUpload: _vm.closeUploadModal },
            model: {
              value: _vm.uploadModal,
              callback: function($$v) {
                _vm.uploadModal = $$v
              },
              expression: "uploadModal"
            }
          })
        : _vm._e(),
      _vm.cancelDialog
        ? _c("CancelDialog", {
            attrs: { selectedContract: _vm.selectedContract },
            on: { cancel: _vm.cancel, closeCancel: _vm.closeCancelDialog },
            model: {
              value: _vm.cancelDialog,
              callback: function($$v) {
                _vm.cancelDialog = $$v
              },
              expression: "cancelDialog"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }